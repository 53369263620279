import React from 'react';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const validationSchema = (t) => {
  return (
    Yup.object({
      email: Yup.string()
        .email().trim()
        .required(t('login.validation.emailRequired')),
      password: Yup.string()
        .min(8, t('login.validation.passwordMinLen'))
        .required(t('login.validation.required')),
    })
  );
};

const Login = ({ loginUser, error }) => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('login.header')}</h4>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema(t)}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            loginUser(values);
            setSubmitting(false);
          }, 0);
        }}
      >
        <Form className="auth-form">
          <Field name="email" type="text" placeholder="email" />
          <ErrorMessage component="span" name="email" />
          <Field name="password" type="password" placeholder="password" />

          <button type="submit">{t('login.btn')}</button>
          {error && <div><span>{error}</span></div>}
        </Form>
      </Formik>
    </>
  );
};

export default Login;
