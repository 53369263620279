import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as timeago from 'timeago.js';
import { withFirebase } from '../Firebase';
import Header from '../Header';
import FooterNav from '../FooterNav';
import ReactMarkdown from 'react-markdown';

const HomePage = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="wrapper">
      <Header />
      <HomeLanding  t={t} i18n={i18n} />
      <FooterNav />
    </div>
  )
};

class HomeLandingBase extends Component {
  state = {
    lastContrib: '',
    lastSignup: '',
    lastUpvote: ''
  }

  async componentDidMount() {
    document.title = "leveler"
    this.getLastUpdates()
	}

  render() {
    const { lastContrib, lastSignup, lastUpvote } = this.state;
    const { t, i18n } = this.props;
    const supportedLngs = i18n.options.supportedLngs.filter(lang => lang !== 'cimode');
    return (
      <section>
        {
          supportedLngs.length > 1 && (
            <select onChange={e => i18n.changeLanguage(e.target.value)} >
              {supportedLngs.map( (lang, i) =>
                <option key={lang} value={lang} >{lang}</option>
              )}
            </select>
          )
        }
        <ReactMarkdown source={t('home.headline')}/>
        <ReactMarkdown source={t('home.instructions')}/>
        <div className="main-btn-container">
          <div className="btn-text-container">
            <Link to="/distribute" className="dist-btn" onClick={this.onDistributeClick}>
              <button className="btn">{t('home.distributeButtonText')}</button>
            </Link>
            { lastContrib && <p className="home-misc">{t('home.distributeLastActivity', {lastContrib})}</p> }
          </div>
          <div className="btn-text-container">
            <Link to="/signup" onClick={this.onReceiveClick}>
              <button className="btn">{t('home.receiveButtonText')}</button>
            </Link>
            { lastSignup && <p className="home-misc">{t('home.receiveLastActivity', {lastSignup})}</p> }
          </div>
          <div className="btn-text-container">
            <Link to="/resources" onClick={this.onResourcesClick}>
              <button className="btn">{t('home.resourcesButtonText')}</button>
            </Link>
            { lastUpvote && <p className="home-misc">{t('home.resourceLastActivity', {lastUpvote})}</p> }
          </div>
          <a
            href="https://leveler.typeform.com/to/rR7dPgdm"
            onClick={this.onNewLevelerClick}
            target="_blank"
            rel="noopener noreferrer"
           >
            <button className="btn">{t('home.createGroupButtonText')}</button>
          </a>
        </div>
      </section>
    );
  }

  getLastUpdates = async () => {
    const { groupStatsDoc } = this.props.firebase;
    const { t } = this.props;
    try {
      await groupStatsDoc
        .get()
        .then((docSnap) => {
          const data = docSnap.data();
          this.setState({
            lastContrib: data.lastContrib ? timeago.format(data.lastContrib.toDate()) : t('generic.neverTime'),
            lastSignup: data.lastSignup ? timeago.format(data.lastSignup.toDate()) : t('generic.neverTime'),
            lastUpvote: data.lastUpvote ? timeago.format(data.lastUpvote.toDate()) : t('generic.neverTime'),
          })
        });
    } catch(e) {
      console.log(e.message)
    }
  }

  onDistributeClick = () => {
    const { logEvent } = this.props.firebase;
    logEvent("distribute_btn_clicked_home");
  };

  onReceiveClick = () => {
    const { logEvent } = this.props.firebase;
    logEvent("receive_btn_clicked_home");
  };
  onResourcesClick = () => {
    const { logEvent } = this.props.firebase;
    logEvent("resources_btn_clicked_home");
  };

  onNewLevelerClick = () => {
    const { logEvent } = this.props.firebase;
    logEvent("new_leveler_btn_clicked_home");
  }
}

const HomeLanding = withFirebase(HomeLandingBase);

export default HomePage;

export { HomeLanding };
// export default withTranslation()(News);
