/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import FooterNav from '../FooterNav';
import { Paypal } from '../Icons';

const ContributePage = () => (
  <div className="wrapper">
    <Header />
    <ContributeBody />
    <FooterNav />
  </div>
);

const ContributeBody = () => {
  const { t } = useTranslation();
  return (
    <section>
      <ReactMarkdown source={t('contribute.headline')} />
      <ReactMarkdown source={t('contribute.instructions')} />
      <a
        href="http://paypal.me/studiodba"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Paypal width="45" />
      </a>
    </section>
  );
};

export default (ContributePage);

export { ContributeBody };
