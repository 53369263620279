import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

const DistributeHeader = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ReactMarkdown className="distribute-header-main" source={t('distribute.headline')} />
      <ReactMarkdown className="distribute-header" source={t('distribute.instructions')} />
    </div>
  );
};
export default DistributeHeader;
