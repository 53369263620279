import React, { useCallback, useState, useEffect } from 'react';
import zip from 'lodash/zip';
import AuthModal from '../Modal/AuthModal';
import ResourcesHeader from '../Resources/ResourcesHeader';
import ResourceItem from '../Resources/ResourceItem';
import CommentForm from '../Form/Comment';
import CommentItem from './CommentItem';
import Loading from '../Animations/Loading';
import withResourceItem from '../Resources/withResourceItem';
import Header from '../Header';
import FooterNav from '../FooterNav';
import { withAuthentication } from '../Session';
import { useTranslation } from 'react-i18next';

const CommentPage = withAuthentication((props) => (
  <div className="wrapper">
    <Header />
    <Comment resourceId={props.match.params.resourceId} {...props} />
    <FooterNav />
  </div>
));

const Comment = withResourceItem(({
  item,
  index,
  firebase,
  userData,
  authUser,
}) => {
  const [comments, setComments] = useState(null);
  const [newComment, setNewComment] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const {
    id,
    path,
  } = item;

  const addToCommentsTree = (commentsTree, commentToInsert) => {
    const { parent } = commentToInsert;

    return commentsTree.map((comment) => {
      const { comments, path } = comment;

      if (parent.includes(path)) {
        /*
          only traverse subtree if it is a subpath to the parent of the new comment item
          if new comment parent is same as comment path, then comment is the parent of the comment to insert
          otherwise, it's one of the comment's descendants, so we need to traverse down the tree
        */
        const updatedComments = path === parent ? [commentToInsert, ...comments] : addToCommentsTree(comments, commentToInsert);

        comment.comments = updatedComments;
      }

      return comment;
    });
  };

  const handleNewComment = useCallback(
    async (comment) => {
      if (comment) {
        const { parent } = comment;

        /*
          if a top level comment, simply add to front of comments array
          reply comments require traversing the comments tree
        */
        const updatedComments = path === parent ? [comment, ...comments] : addToCommentsTree(comments, comment);
        setComments(updatedComments);

        setNewComment(true);
      }
      setNewComment(false);
    },
    // TODO: FIGURE OUT HOW TO FIX THIS
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comments, setNewComment],
  );

  useEffect(() => {
    // get initial comments
    const { dbFs } = firebase;

    const getCommentsHelper = async (docPath) => {
      try {
        const querySnapshot = await dbFs
          .collection(`${docPath}/comments`)
          .orderBy('score', 'desc')
          .get();

        const snapshots = [];
        querySnapshot.forEach((documentSnapshot) => {
          snapshots.push(documentSnapshot);
        });

        const responses = await Promise.all(snapshots.map((snapshot) => getCommentsHelper(snapshot.ref.path)));
        const result = zip(snapshots, responses).map((item) => {
          const [snapshot, comments] = item;
          const data = snapshot.data();
          data.path = snapshot.ref.path;
          data.comments = comments;
          return data;
        });
        return result;
      } catch (e) {
        console.error(e.message);
      }
    };

    const getComments = async () => {
      const commentsArr = await getCommentsHelper(path);
      setComments(commentsArr);
    };

    getComments();
  }, [firebase, path]);

  useEffect(() => {
    if (newComment) {
      handleNewComment();
    }
  }, [newComment, handleNewComment]);

  // AUTH MODAL STATE MANAGEMENT
  const toggleModal = () => {
    setOpen(!isOpen);
  };

  const logout = () => {
    firebase.doSignOut();
  };

  return (
    <>
      <AuthModal
        toggleModal={toggleModal}
        isOpen={isOpen}
        firebase={firebase}
      />
      <ResourcesHeader
        toggleModal={toggleModal}
        userData={userData}
        logout={logout}
      />

      <div className="comment-page">
        <ResourceItem
          key={id}
          index={index}
          item={item}
          view="comment"
          comments={comments}
          userData={userData}
        />
        {userData
          ? (
            <CommentForm
              path={path}
              handleNewComment={handleNewComment}
              parent={path}
              authUser={authUser}
              userData={userData}
            />
          )
          : <span>{t('comments.login')}</span>}
        <div className="comments-container">
          {comments
            ? (comments.map((comment) => <CommentItem key={comment.path} parent={path} handleNewComment={handleNewComment} comment={comment} />)) : <Loading height="70" width="70" />}
        </div>
      </div>
    </>
  );
});

export default CommentPage;
export { Comment };
