//filename looks like: ./leveler/en.json
// so capture group and locale from that, e.g. ./(group)/(locale).json
const localeMatchingRegex = /^\.\/([^/]+)\/([^.]+)\.json$/i;
//                             . /(group) /(locale).json

const getLocaleCodeFromFilename = filename => {
  const matches = localeMatchingRegex.exec(filename);
  //matches[0] == whole matched string
  //matches[1] == group
  //matches[2] == locale
  return (matches && matches.length === 3) ? {group: matches[1], locale: matches[2]} : null;
}

/* Load all translations/i18n/l10n files from public/i18n dir & subdirs,
 * dump them in a resources object for use in i18next library. 
 * The returned resource object looks like this:
 * {
 *   locale: {
 *     group: {
 *       key: value
 *     }
 *   }
 * }
 */
const loadResources = () => {
  // Use webpack-specific loader to load all l10n/i18n files from all subdirectories
  // see https://webpack.js.org/api/module-methods/#requirecontext
  const webpackContext = require.context('../public/i18n'); 

  const resources = {};

  webpackContext.keys().forEach(filename => {
    const captures = getLocaleCodeFromFilename(filename);
    if (captures) {
      const {group, locale} = captures;
      resources[locale] = resources[locale] || {};
      resources[locale][group] = webpackContext(filename);
    }
  });

  return resources;
}

export default loadResources;
