import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const EmailAddressInUse = (props) => {
  const { t } = useTranslation();
  const email = t('generic.generalEmail');

  return (
    <>
      {props.isOpen ? (
        <>
          <div className="modal">
            <Trans
              i18nKey="modals.emailInUse"
              values={{ email }}
              components={[<a href={`mailto:${email}`} />]} // eslint-disable-line jsx-a11y/anchor-has-content
            />
          </div>
          <div className="modal-overlay" onClick={() => props.closeModal()} />
        </>
      ) : null}
    </>
  );
}

export default EmailAddressInUse;
