/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { withAuthentication } from '../Session';

const errorStyle = {
  color: 'red',
  fontSize: '1rem',
};

const validationSchema = (t) => {
  return (
    Yup.object({
      text: Yup.string()
        .trim()
        .min(10)
        .max(1000)
        .required(t('comments.validation.textRequired')),
    })
  );
};

const CommentForm = ({
  firebase, authUser, userData, handleNewComment, reply, parent,
}) => {
  const { t } = useTranslation();

  const [didSubmit, setSubmit] = useState(false);

  const updateUser = async (commentId) => {
    const { fieldValue, userCollection } = firebase;
    try {
      await userCollection.doc(authUser.uid)
        .update({
          karma: fieldValue.increment(2),
          submitted: fieldValue.arrayUnion(commentId),
        });
    } catch (e) {
      console.log(e);
    }
  };

  const updateResourceDescendants = async () => {
    const { fieldValue, resourcesCollection } = firebase;
    const resourceId = parent.split('/')[1];
    const resourceRef = resourcesCollection.doc(resourceId);

    try {
      await resourceRef
        .update({
          descendants: fieldValue.increment(1),
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  const writeComment = async (comment) => {
    const { dbFs } = firebase;
    // eslint-disable-next-line no-param-reassign
    const {
      by, created, score, text,
    } = comment;
    try {
      const newComment = {
        by,
        created,
        score,
        text,
        parent,
      };

      const write = await dbFs.collection(`${parent}/comments`).add({
        by,
        created,
        score,
        text,
        parent,
      });
      await updateUser(write.id);

      updateResourceDescendants();
      handleNewComment(newComment);
    } catch (e) {
      console.log(e.message);
    }
  };

  const onSubmit = async (values) => {
    const { text } = values;
    const { id } = userData;
    const { fieldValue } = firebase;

    const comment = {
      text,
      by: id,
      score: 1,
      created: fieldValue.serverTimestamp(),
    };

    writeComment(comment);
    values.text = '';
  };

  return (
    <>
      {!didSubmit && authUser
          && (
          <Formik
            initialValues={{ text: '' }}
            validationSchema={validationSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                onSubmit(values);
                setSubmitting(false);
                setSubmit(true);
              }, 0);
            }}
          >
            <Form className={`comment-form ${reply ? 'reply' : ''}`}>
              <Field
                name="text"
                type="text"
                component="textarea"
              />
              <button
                className="btn"
                type="submit"
              >
                {t('comments.addComment')}
              </button>
              <p style={errorStyle}><ErrorMessage name="text" /></p>
            </Form>
          </Formik>
          )}
      {!authUser
      && (
      <div className="login-prompt">
        <p>{t('comments.signInRequired')}</p>
      </div>
      )}
      {didSubmit && !reply
        && (
        <div className="success-msg">
          <p>
            <span aria-hidden="true">💭</span>
            {t('comments.thanks')}
            <span aria-hidden="true">🗣</span>
          </p>
        </div>
        )}
    </>
  );
};

CommentForm.propTypes = {
  firebase: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  handleNewComment: PropTypes.func.isRequired,
  reply: PropTypes.bool,
  parent: PropTypes.string.isRequired,
};

export default withAuthentication(CommentForm);
