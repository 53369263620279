import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Header from '../Header';
import FooterNav from '../FooterNav';

const AboutPage = () => (
  <div className="wrapper">
    <Header />
    <AboutBody />
    <FooterNav />
  </div>
);

const AboutBody = () => {
  const { t } = useTranslation();

  return (
    <section>
      <ReactMarkdown source={t('about.text')} />
    </section>
  );
};

export default AboutPage;

export { AboutBody };
