import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import Header from '../Header';
import FooterNav from '../FooterNav';
import Registration from './registration';
import { withTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

const SignUpPage = () => (
  <div className="wrapper">
    <Header />
    <SignUpForm />
    <FooterNav />
  </div>
);

class SignUpFormBase extends Component {
  componentDidMount() {
    document.title = this.props.t('signUp.pageTitle');
  }

  render() {
    return (
      <section className="signup-form">
        <ReactMarkdown source={this.props.t('signUp.pageHeading')} />
        <ol>
          <li className="bold">{this.props.t('signUp.instructionOne')}</li>
          <li>{this.props.t('signUp.instructionTwo')}</li>
          <li>{this.props.t('signUp.instructionThree')}</li>
        </ol>
        <p className="legend">{this.props.t('signUp.legend')}</p>
        <Registration firebase={this.props.firebase} />
      </section>
    );
  }
}

const SignUpForm = withTranslation()(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm };
