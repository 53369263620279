import * as React from "react";

function SvgQuestionMark(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M256 0C114.512 0 0 114.497 0 256c0 141.488 114.497 256 256 256 141.488 0 256-114.497 256-256C512 114.512 397.503 0 256 0zm0 477.867c-122.337 0-221.867-99.529-221.867-221.867S133.663 34.133 256 34.133 477.867 133.663 477.867 256 378.337 477.867 256 477.867z" />
      <path d="M249.507 311.95c-12.122 0-21.935 10.101-21.935 22.224 0 11.833 9.524 22.224 21.935 22.224s22.224-10.391 22.224-22.224c0-12.123-10.102-22.224-22.224-22.224zM253.259 143.679c-38.964 0-56.86 23.091-56.86 38.676 0 11.257 9.526 16.452 17.318 16.452 15.586 0 9.237-22.224 38.677-22.224 14.432 0 25.977 6.349 25.977 19.627 0 15.585-16.163 24.534-25.688 32.614-8.371 7.216-19.339 19.05-19.339 43.873 0 15.007 4.041 19.338 15.874 19.338 14.144 0 17.03-6.35 17.03-11.834 0-15.007.288-23.667 16.162-36.078 7.794-6.061 32.327-25.688 32.327-52.819s-24.533-47.625-61.478-47.625z" />
    </svg>
  );
}

export default SvgQuestionMark;
