/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';
import * as timeago from 'timeago.js';
import { withFirebase } from '../Firebase';
import usePersistedState from '../../utils/usePersistedState';
import ShareBtn from './ShareBtn';

const addURLScheme = (url) => (/^https?:\/\//.test(url) ? url : `http://${url}`);

const ResourceItem = ({
  item, getByCategory, view, userData, firebase,
}) => {
  const [didVote, setVote] = usePersistedState(`didVoteLink-${item.id}`, null);
  const [score, setScore] = useState(item.score);

  const {
    id,
    title,
    url,
    by,
    created,
    category,
    descendants,
    text,
  } = item;

  const {
    fieldValue,
    resourcesCollection,
    userCollection,
    groupStatsDoc,
  } = firebase;

  const itemLink = `${window.location.origin}/resources/c/${id}`;

  const date = created.toDate();
  const formattedCreateDate = timeago.format(date);

  const updateUserKarma = async (uid) => {
    const user = userCollection.where('id', '==', uid);
    await user.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const userRef = userCollection.doc(doc.id);
        userRef.update({ karma: fieldValue.increment(1) });
      });
    });
  };

  const updateLastUpvote = async (updated) => {
    try {
      await groupStatsDoc.update({
        lastUpvote: updated,
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const upvote = async () => {
    updateUserKarma(item.by);

    // UPDATE LINK SCORE
    const docRef = resourcesCollection.doc(item.id);
    try {
      await docRef.update({ score: fieldValue.increment(1) });
    } catch (e) {
      console.log(e.message);
    }
    // UPDATE LOCAL SCORE
    setScore(score + 1);
    // UPDATE LAST UPDATED TIME OF UPVOTE
    updateLastUpvote(fieldValue.serverTimestamp());
    setVote(true);
  };

  const resourceUpvote = async () => {
    await upvote();
  };

  const linkClicked = async (url) => {
    const { logEvent } = firebase;
    await logEvent('resource_link_clicked', { url });
  };

  const renderItemVotes = () => {
    if (userData) {
      if (userData.id !== by && !didVote) {
        return (
          <>
            <button type="button" onClick={() => resourceUpvote()}>
              <span aria-label="upvote" role="img">⬆️</span>
            </button>
            <p aria-label="upvotes">{score}</p>
          </>
        );
      } if (userData.id === by && !didVote) {
        return (
          <>
            <span aria-label="You created this item" role="img">📝</span>
            <p aria-label="upvotes">{score}</p>
          </>
        );
      } if (didVote) {
        return (
          <>
            <span aria-label="You upvoted this" role="img">⭐️</span>
            <p aria-label="upvotes">{score}</p>
          </>
        );
      }
    }
  };

  return (
    <>
      <div key={id} className={`resources-item-container ${view === 'comment' ? 'comment-page-resource' : ''}`}>
        <div className="resources-item-votes">
          {renderItemVotes()}
        </div>
        <div className="resources-item-title">
          <>
            {url && (
              <>
                <a
                  href={addURLScheme(url)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => linkClicked(url)}
                >
                  {title}
                </a>
                <a
                  href={addURLScheme(url)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => linkClicked(url)}
                  id="link-emoji"
                >
                  {' '}
                  🔗
                  {' '}
                </a>
              </>
            )}
            {!url && view !== 'comment' && (
              <Link
                id="no-link"
                to={`/resources/c/${id}`}
              >
                {title}
              </Link>
            )}
            {!url && view === 'comment' && (

            <Link
              to={`/resources/c/${id}`}
            >
              {title}
            </Link>
            )}
            <Link
              id="comments"
              to={`/resources/c/${id}`}
            >
              (
              {descendants}
              )
              <span> 💬</span>
            </Link>
          </>

          <div className="resources-item-footer">
            <p>
              by
              {' '}
              {by}
              ,
              {' '}
              {formattedCreateDate}
            </p>
            <ShareBtn url={itemLink} />
          </div>
          {view !== 'comment'
          && (
          <div className="resources-item-category-mobile">
            <button type="button" onClick={() => getByCategory(category)}><b>{category}</b></button>
          </div>
          )}
        </div>
        {view !== 'comment'
        && (
        <div className="resources-item-category-desktop">
          <button type="button" onClick={() => getByCategory(category)}><b>{category}</b></button>
        </div>
        )}
      </div>
      {view === 'comment'
       && <div className="resources-item-description"><Linkify><p>{text}</p></Linkify></div>}

    </>
  );
};

ResourceItem.propTypes = {
  firebase: PropTypes.object.isRequired,
  getByCategory: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    by: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    created: PropTypes.any.isRequired,
    descendants: PropTypes.number,
    score: PropTypes.number.isRequired,
    url: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  view: PropTypes.string,
  userData: PropTypes.shape({
    about: PropTypes.string,
    created: PropTypes.any.isRequired,
    id: PropTypes.string.isRequired,
    karma: PropTypes.number.isRequired,
  }).isRequired,
};

export default withFirebase(ResourceItem);
