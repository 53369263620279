import React, { Component } from "react";
import { withFirebase } from '../Firebase';
import Header from '../Header';
import FooterNav from '../FooterNav';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { withTranslation } from 'react-i18next';

const SuccessPage = () => (
	<div className="wrapper">
		<Header />
		<SuccessLanding  />
		<FooterNav />
	</div>
);

const MAILCHIMP_RECEIVER_URL = "https://leveler.us19.list-manage.com/subscribe/post?u=684bbb5a5125c30c1f0016222&amp;id=dc37d76685";

const INITIAL_STATE = {
	entries: []
};

class SuccessLandingBase extends Component {
	state = { ...INITIAL_STATE };

  async componentDidMount() {
		document.title = this.props.t('success.pageTitle');
	}

  render() {
    return (
      <section>
        <h3>{this.props.t('success.pageHeading')}</h3>
        <p>{this.props.t('success.status')}</p>
        <p>{this.props.t('success.howToGetUpdates')}</p>
        <div className="mailchimp-form">
          <MailchimpSubscribe url={MAILCHIMP_RECEIVER_URL} />
        </div>
      </section>
    );
  }
};

const SuccessLanding = withTranslation()(withFirebase(SuccessLandingBase));

export default SuccessPage;

export { SuccessLanding };