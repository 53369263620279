import React, { Component } from 'react';
import Header from '../Header';
import { Loading } from '../Animations';
import AuthModal from '../Modal/AuthModal';
import ResourcesHeader from './ResourcesHeader';
import ResourceItem from './ResourceItem';
import FooterNav from '../FooterNav';
import { withAuthentication } from '../Session';

const TopResourcesPage = () => (
	<div className="wrapper">
		<Header />
		<TopResourcesContainer />
		<FooterNav />
	</div>
)

const INITIAL_STATE = {
	links: [],
	loading: true,
	isOpen: false,
};

class TopResourcesContainerBase extends Component {
	state = { ...INITIAL_STATE };

	componentDidMount() {
		document.title = "leveler: top resources"
		this.getTopLinks(true);
	}

	sortByDate(numDays) {
		const d = new Date();
		const sortDate = new Date(d.setDate(d.getDate() - numDays));
		return sortDate;
	}

	getTopLinks = async (numDays = 3) => {
		this.setState({
			loading: true
		})

		let links = [];
		const { resourcesCollection } = this.props.firebase;
		const limit = 30;

		try {
			await resourcesCollection
				.where("created", ">", this.sortByDate(numDays))
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						let link = doc.data();
						// SET UP ID
						link.id = doc.id;
						// SET UP PATH
						link.path = doc.ref.path;
						// PREP FOR STATE
						links.push(link)
					})
				})
				if (links && links.length >= limit) {
					links = links
						.sort((a, b) => b.score - a.score)
						.slice(0, limit);
					this.setState({
						links,
						loading: false
					})
				} else {
					this.getTopLinks(numDays * 2); // TODO: Could instead add 1, 2, ... or multiply by a smaller number?
				}
		} catch(e) {
			console.log(e.message)
		}
	}

	render() {
		const { isOpen } = this.state;
		const {
			firebase,
			userData,
		} = this.props;
		const {
			doSignOut,
			logEvent,
			resourcesCollection,
		} = firebase;

		const getByCategory = async (category) => {
			this.setState({
				loading: true
			})
			// GA EVENT
			logEvent("resource_category_clicked", {category: category});
			let links = [];
			try {
				await resourcesCollection
					.where("type", "==", "story")
					.where("category", "==", category)
					.limit(30)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							let link = doc.data();
							// SET UP ID
							link.id = doc.id;
							// SET UP PATH
							link.path = doc.ref.path;
							// PUSH TO STATE
							links.push(link)
						})
					})
					if (links) {
						this.setState({
							links,
							loading: false
						})
					}
				} catch(e) {
					console.log(e.message)
				}
		}

		const logout = () => {
			doSignOut()
		}

		// MODAL STATE MANAGEMENT
		const toggleModal = (isOpen) => {
			this.setState({
				isOpen: !isOpen
			})
		}

		return (
			<>
				<AuthModal
					toggleModal={toggleModal}
					isOpen={isOpen}
					firebase={firebase}
				/>
				<ResourcesHeader
					userData={userData}
					toggleModal={toggleModal}
					logout={logout}
				/>

				<div className="resources-body">
					{this.state.loading && <Loading height="70" width="70"/>}

					{!this.state.loading ? (
						this.state.links.map((item) =>
							<ResourceItem
								key={item.id}
								item={item}
								getByCategory={getByCategory}
								userData={userData}
							/>
						)
					): null}
				</div>
			</>
		)
	}
}

const TopResourcesContainer = withAuthentication(TopResourcesContainerBase);

export default TopResourcesPage;

export { TopResourcesContainer };
