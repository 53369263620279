import React from 'react';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const validationSchema = (t, checkUsername, firebase) => (
  Yup.object({
    username: Yup.string()
      .min(3, t('userSignUp.validation.usernameMinLen'))
      .test('username-taken', t('userSignUp.validation.usernameTaken'), (value) => checkUsername(value, firebase))
      .required(t('userSignUp.valiation.usernameRequired')),
    email: Yup.string()
      .email().trim()
      .required(t('userSignUp.validation.emailRequired')),
    password: Yup.string()
      .min(8, t('userSignUp.validation.passwordMinLen'))
      .required(t('userSignUp.validation.passwordRequired')),
  })
);

const checkUsername = async (entry, fb) => {
  const { userCollection } = fb;
  const result = await userCollection.where('id', '==', entry).get();
  return result.empty;
};

const SignUp = ({ signUpUser, error, firebase }) => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('userSignUp.header')}</h4>
      <p>{t('userSignUp.text')}</p>
      <Formik
        initialValues={{ username: '', email: '', password: '' }}
        validationSchema={validationSchema(t, checkUsername, firebase)}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            signUpUser(values);
            setSubmitting(false);
          }, 0);
        }}
      >
        <Form className="auth-form">
          <Field name="username" type="text" placeholder="username" />
          <ErrorMessage component="span" name="username" />
          <Field name="email" type="text" placeholder="email" />
          <ErrorMessage component="span" name="email" />
          <Field name="password" type="password" placeholder="password" />
          <ErrorMessage component="span" name="password" />
          <button type="submit">{t('userSignUp.btn')}</button>
          {error && <div><span>{error}</span></div>}
        </Form>
      </Formik>
    </>
  );
};

export default SignUp;
