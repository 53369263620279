import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import loadResources from './i18n-static-resource-loader';

const resources = loadResources();

const group = process.env.REACT_APP_LEVELER_GROUP;

const supportedLngs = Object.keys(resources).filter(lang => group in resources[lang]);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // fallbackLng, //let's not fallback
    supportedLngs, //Limits what languages can be set
    fallbackNS: [group, 'leveler'], //'ns' option should work but doesn't seem to. 'fallbackNS' works as expected
    resources,
    interpolation: {
      escapeValue: false, //so that we don't double-escape in React? 
    },    
  });

export default i18n;
