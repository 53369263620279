
const validate = (value, t) => {
  if (!value) return false;
  value = (/^https?:\/\//.test(value) ? value : `https://${value}`);

  const url = new URL(value);
  const { hostname, pathname, search } = url;
  const path = pathname + search;

  const [regex, errMsg] = (function (hostname) {
    switch (hostname) {
      case 'cash.app':
        return [/^\/\$[^/]+$/, t('signUp.validation.cashError')];
      case 'paypal.com':
      case 'www.paypal.com':
        return [/^\/[^/]+$/, t('signUp.validation.paypalError')];
      case 'paypal.me':
      case 'www.paypal.me':
        return [/^\/[^/]+$/, t('signUp.validation.paypalError')];
      case 'venmo.com':
        return [/^\/code\?([^=]+=[^&]+&)*user_id=[0-9]{19}(&[^=]+=[^&]+)*$/, t('signUp.validation.venmoError')];
      default:
        return [null, t('signUp.validation.defaultError')];
    }
  }(hostname));

  return (!!path && !!regex && regex.test(path)) ? true : errMsg;
}
export default validate;
