/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormikPlacesAutocomplete from './FormikPlacesAutocomplete.jsx';
import PaymentInstruction from '../Modal/PaymentInstruction';
import RadioButton from './RadioButton';
import RadioButtonGroup from './RadioButtonGroup';
import validatePayment from '../../utils/validatePayment';
import EmailAddressInUse from '../Modal/EmailAddressInUse';
import PaymentLinkInUse from '../Modal/PaymentLinkInUse';

const group = process.env.REACT_APP_LEVELER_GROUP;

const REQUIRED_ERROR = 'required';

const addURLScheme = (url) => (/^https?:\/\//.test(url) ? url : `https://${url}`);

const validationSchema = (t) => (
  Yup.object().shape({
    email: Yup.string().email().trim().required(REQUIRED_ERROR),
    industry: Yup.string().min(1).required(),
    description: Yup.string().min(1).required(REQUIRED_ERROR),
    social_url: Yup.string()
      .transform(addURLScheme)
      .url(t('signUp.validation.socialURL')),
    disclaimer: Yup.string().min(1).required(),
    payment: Yup.string()
      .transform(addURLScheme)
      .url(t('signUp.validation.paymentURL'))
      .test('validPaymentLink', '', function (value) {
        const result = validatePayment(value, t);
        if (result === false || result === true) {
          return result;
        }
        return this.createError({ message: result });
      })
      .required(REQUIRED_ERROR),
  })
);

const Registration = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [paymentLinkInUse, setPaymentLinkInUse] = useState(false);
  const [emailAddressInUse, setEmailAddressInUse] = useState(false);
  const { t } = useTranslation();

  // MODAL STATE MANAGEMENT
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const updateLastSignup = async (updated) => {
    const { groupStatsDoc } = props.firebase;
    try {
      await groupStatsDoc.update({
        lastSignup: updated,
      });
    } catch (e) {
      console.error(e.message);
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    if (values.industry === 'other') {
      values.industry = values.other_industry;
      delete values.other_industry;
    }
    values.payment = addURLScheme(values.payment);
    values.payment = values.payment.trim().toLowerCase();
    const { entriesCollection, fieldValue, dbFs } = props.firebase;

    // check that email is not already in entries collection
    const email = values.email.trim().toLowerCase();

    // check that payment url is not already in entries collection
    const validateUniquePaymentURL = await entriesCollection
      .where('payment_url', 'array-contains', values.payment)
      .get();
    if (!validateUniquePaymentURL.empty) {
      setPaymentLinkInUse(true);
      return;
    }

    const writeBatch = dbFs.batch();
    const entriesRef = entriesCollection.doc();
    const privateRef = entriesRef.collection('private').doc();

    writeBatch
      .set(entriesRef, {
        location: values.location,
        industry: values.industry.trim(),
        description: values.description.trim(),
        payment_url: [values.payment],
        suggestion: values.suggestion.trim(),
        shown: 0,
        potential_contrib: 0,
        group,
        random: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      })
      .set(privateRef, {
        email,
        social_url: values.social_url.trim(),
      })
      .commit()
      .then(() => {
        updateLastSignup(fieldValue.serverTimestamp());
        resetForm({});
        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <Formik
      initialValues={{
        email: '',
        industry: process.env.REACT_APP_LEVELER_DISABLE_INDUSTRY ? `DISABLE_INDUSTRY_${group.toUpperCase()}` : '',
        description: '',
        payment: '',
        suggestion: '',
        location: '',
        social_url: '',
        disclaimer: '',
      }}
      validationSchema={validationSchema(t)}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnChange
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        dirty,
        isValid,
      }) => (
        <Form onSubmit={handleSubmit}>
          <fieldset>
            <label htmlFor="email">
              {t('signUp.labelEmail')}
            </label>
            <span className="description">
              {t('signUp.instructionEmail', {
                defaultValue: null,
              })}
            </span>
            <Field
              type="email"
              name="email"
              placeholder="email"
              value={values.email}
            />
            <span className="error">{errors.email}</span>
          </fieldset>
          <fieldset>
            <label htmlFor="location">
              {t('signUp.labelLocation')}
              *
            </label>
            <span className="description">
              {t('signUp.instructionLocation')}
            </span>
            <Field
              name="location"
              component={FormikPlacesAutocomplete}
              value={values.location}
            />
            <span className="error">{errors.location}</span>
          </fieldset>
          {process.env.REACT_APP_LEVELER_DISABLE_INDUSTRY || (
            <RadioButtonGroup
              id="radioGroup"
              htmlfor="industry"
              label={`${t('signUp.labelIndustry')}*`}
              value={values.industry}
              error={errors.industry}
              touched={touched.industry}
            >
              <span className="description">
                {t('signUp.instructionIndustry', {
                  defaultValue: null,
                })}
              </span>
              <Field
                component={RadioButton}
                name="industry"
                id="Arts"
                label={t('signUp.artsRadioLabel')}
              />
              <Field
                component={RadioButton}
                name="industry"
                id="Nightlife"
                label={t('signUp.nightlifeRadioLabel')}
              />
              <Field
                component={RadioButton}
                name="industry"
                id="Production"
                label={t('signUp.productionRadioLabel')}
              />
              <Field
                component={RadioButton}
                name="industry"
                id="Food Service/Hospitality"
                label={t('signUp.hospitalityRadioLabel')}
              />
              <Field
                component={RadioButton}
                name="industry"
                id="Music"
                label={t('signUp.musicRadioLabel')}
              />
              <Field
                component={RadioButton}
                name="industry"
                id="other"
                label={t('signUp.otherRadioLabel')}
              />
              {values.industry === 'other' && (
                <div>
                  <input
                    type="text"
                    onChange={handleChange}
                    value={values.custom_industry}
                    name={t('signUp.otherRadioName')}
                    placeholder={t('signUp.otherRadioPlaceholder')}
                  />
                </div>
              )}
              <span className="error">{errors.industry}</span>
            </RadioButtonGroup>
          )}
          <fieldset>
            <label htmlFor="description">
              {t('signUp.labelContext')}
              *
            </label>
            <span className="description">
              {t('signUp.instructionContext')}
            </span>
            <Field
              as="textarea"
              name="description"
              maxLength="300"
              value={values.description}
            />
            <span className="error">{errors.description}</span>
          </fieldset>
          <fieldset>
            <label htmlFor="payment">
              {t('signUp.labelPayment')}
              *
            </label>
            <span className="description">
              {t('signUp.instructionPayment')}
            </span>
            <input
              type="text"
              onChange={handleChange}
              value={values.payment}
              name="payment"
            />

            {errors.payment && (
            <>
              <span className="error">
                {errors.payment}
                <button className="instructions-btn" onClick={openModal}>instructions</button>
              </span>
            </>
            )}
            <PaymentInstruction
              openModal={openModal}
              closeModal={closeModal}
              isOpen={modalIsOpen}
              data={errors.payment}
            />
            <EmailAddressInUse
              isOpen={emailAddressInUse}
              closeModal={() => setEmailAddressInUse(false)}
            />
            <PaymentLinkInUse
              isOpen={paymentLinkInUse}
              closeModal={() => setPaymentLinkInUse(false)}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="social_url">{t('signUp.labelSocial')}</label>
            <span className="description">
              {t('signUp.instructionSocial')}
            </span>
            <input
              type="text"
              onChange={handleChange}
              value={values.social_url}
              name="social_url"
            />
            <span className="error">{errors.social_url}</span>
          </fieldset>
          <fieldset>
            <label htmlFor="suggestion">{t('signUp.labelSuggestion')}</label>
            <span className="description">
              {t('signUp.instructionSuggestion')}
            </span>
            <Field
              as="textarea"
              maxLength="300"
              value={values.suggestion}
              name="suggestion"
            />
          </fieldset>
          <RadioButtonGroup
            id="radioGroup"
            htmlfor="disclaimer"
            label={`${t('signUp.labelDisclaimer')}*`}
            value={values.disclaimer}
            error={errors.disclaimer}
            touched={touched.disclaimer}
          >
            <span className="description">
              {t('signUp.instructionDisclaimer')}
            </span>

            <Field
              component={RadioButton}
              name="disclaimer"
              id="Yes"
              label={t('signUp.disclaimerYesLabel')}
            />
            <span className="error" style={{ top: '35px' }}>{errors.disclaimer}</span>
          </RadioButtonGroup>
          <button
            className="registration-btn"
            type="submit"
            disabled={!(dirty && isValid)}
          >
            {t('signUp.submitBtn')}
          </button>
          {submitted ? <Redirect to="/success" /> : null}
        </Form>
      )}
    </Formik>
  );
};

export default Registration;
