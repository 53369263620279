import React, { useEffect, useState } from 'react';
import { withFirebase } from '../Firebase';

export const withResourceItem = (Component) => withFirebase((props) => {
  const [item, setItem] = useState(null);
  const { resourceId, firebase } = props;

  useEffect(
    () => {
      const fetchResourceItem = async () => {
        const { resourcesCollection } = firebase;
        try {
          const doc = await resourcesCollection
            .doc(resourceId)
            .get();
          const newItem = await doc.data();
          newItem.id = doc.id;
          newItem.path = doc.ref.path;
          setItem(newItem);
       } catch(e) {
          console.log(e.message)
        }
      };
      fetchResourceItem();
    },
    [resourceId, firebase]
  )

  return item ? <Component {...props} item={item} /> : null;
});

export default withResourceItem;
