import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

const PaymentInstruction = (props) => {
  const { t } = useTranslation();
  return (
    <>
      {props.isOpen ? (
        <>
          <div
            aria-modal="true"
            role="dialog"
            className="modal"
          >
            {props.data.includes('Venmo')
          && (
            <ReactMarkdown source={t('modals.paymentInstruction.venmo')} />
          )}
            {props.data.includes('Paypal')
          && (
            <ReactMarkdown source={t('modals.paymentInstruction.paypal')} />
          )}
            {props.data.includes('Cash')
          && (
            <ReactMarkdown source={t('modals.paymentInstruction.cash')} />
          )}
            {props.data.includes('not adding a valid payment link') || props.data.includes('we need a real URL here')
          || props.data.includes('required')
              ? (
                <ReactMarkdown source={t('modals.paymentInstruction.cash')} />
              ) : null}
          </div>
          <div className="modal-overlay" onClick={() => props.closeModal()} />
        </>
      ) : null}
    </>
  );
};

export default PaymentInstruction;
