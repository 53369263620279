/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown/with-html';
import { useTranslation } from 'react-i18next';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';

const ContributeBand = (props) => {
  const onBandClick = () => {
    const { logEvent } = props.firebase;
    logEvent('contribute_url_clicked_contrib_band');
  };
  const { t } = useTranslation();
  return (
		<div className="contribute-band">
			<Link to={ROUTES.CONTRIBUTE} onClick={onBandClick}>
        <span aria-label="Loudspeaker" role="img">📣 </span>
        {' '}
				<ReactMarkdown source={t('header.contributeInfo')} disallowedTypes={['paragraph']} unwrapDisallowed />
        {' '}
				<span aria-label="Heart" role="img">❤️</span>
			</Link>
		</div>
  );
};

ContributeBand.propTypes = {
  firebase: PropTypes.object.isRequired,
};

export default withFirebase(ContributeBand);
