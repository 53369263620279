/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Leveler } from '../Icons';

const ResourcesHeader = ({
  userData,
  logout,
  toggleModal,
}) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  return (
    <nav className="resources-header">
      <ul>
        <NavLink to="/resources" id="leveler-icon">
          <Leveler />
        </NavLink>
        <NavLink
          to="/resources"
          exact
          activeStyle={{ color: '#3d3d3d', fontWeight: 700 }}
        >
          {t('resources.newBtn')}
        </NavLink>
        <NavLink
          to="/resources/top"
          exact
          activeStyle={{ color: '#3d3d3d', fontWeight: 700 }}
        >
          {t('resources.topBtn')}
        </NavLink>
          <NavLink id="submit-link" to="/add-resource">{t('resources.submitBtn')}</NavLink>
        {userData
          ? (
            <>
              <a id="user-id">
                {userData.id}
                {' '}
                (
                {userData.karma}
                )
              </a>
              <span>|</span>
              <button type="button" onClick={() => { logout(); }}>{t('resources.logoutBtn')}</button>
            </>
          )
          : <a onClick={() => { toggleModal(setOpen(isOpen)); }}>{t('resources.loginBtn')}</a>}
      </ul>
    </nav>
  );
};

ResourcesHeader.propTypes = {
  userData: PropTypes.object,
  logout: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
export default ResourcesHeader;
