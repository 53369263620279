import React from 'react';
import Modal from 'react-modal';
import {TwitterShareButton, FacebookShareButton, EmailShareButton, FacebookIcon, TwitterIcon, EmailIcon} from 'react-share';
import { useTranslation } from 'react-i18next';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const ShareModal = (props) => {
  const { t } = useTranslation();
  const shareOptions = {
    url: 'https://leveler.info',
    text: t('modals.shareText'),
    subject: 'leveler.info',
  };
  return (
    <>
      <Modal
        isOpen={props.modalIsOpen}
        style={modalStyles}
        contentLabel="Share Prompt"
      >
        <p>{t('modals.shareHeadline')}</p>
        <p>{t('modals.shareByline')}</p>
        <div className="share-options">
          <div className="network">
            <FacebookShareButton
              url={shareOptions.url}
              quote={shareOptions.text}
              className="share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className="network">
            <TwitterShareButton
              url={shareOptions.url}
              title={shareOptions.text}
              className="share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className="network">
            <EmailShareButton
              url={shareOptions.url}
              subject={shareOptions.subject}
              body={shareOptions.text}
              className="share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
          <button type="button" className="btn" onClick={props.closeModal}>no, thanks</button>
        </div>
        <button type="button" className="close" onClick={props.closeModal}>x</button>
      </Modal>
    </>
  );
}

export default ShareModal;