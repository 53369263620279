import React, { Component } from 'react'
import axios from 'axios'
import { withFirebase } from '../Firebase';
import Header from '../Header';
import { Loading } from '../Animations'
import DistributeHeader from './DistributeHeader';
import DistributeCard from './DistributeCard';
import FooterNav from '../FooterNav';
import DonorForm from '../Form/Donor';
import ShareModal from '../Modal/ShareModal';

const numEntries = 5;				// number of entries to display
const sizeThreshold = 100;	// threshold to switch from grabbing all entries to random entries
const maxQueryTries = 5;		// how many times to try query until there are `numEntries`

const group = process.env.REACT_APP_LEVELER_GROUP;

const DistributePage = () => {

	return (
		<div className="wrapper">
			<Header />
			<DistributeTable/>
			<FooterNav distributePage />
		</div>
	);
};

const INITIAL_STATE = {
	entries: [],
	loading: true,
	ipLocale: null,
	distributed: true,
	showModal: false,
	modalHasBeenShown: false
};

const DEFAULT_LOCALE = {
	country_code: '',
	region_code: '',
};

const { REACT_APP_GEOLOCATION_ENABLED, REACT_APP_IPDATA_KEY } = process.env;

class DistributeTableBase extends Component {

	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
		this.distributeClick = this.distributeClick.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	async componentDidMount() {
		document.title = "leveler: distribute"
		const ipLocale = REACT_APP_GEOLOCATION_ENABLED === 'true' ? await this.getUserLocation() : null;
		this.getEntries(ipLocale)
	}

	getUserLocation = async () => {
		try {
			const res = await axios.get(`https://api.ipdata.co?api-key=${REACT_APP_IPDATA_KEY}`);
			if (res.data) {
				const { country_code, country_name, region_code } = res.data;
				console.log(res.data)
				const ipLocale = {
					country_code,
					country_name,
					region_code
				}
				return ipLocale;
			}
		} catch(e) {
			console.log(e.message)
		}
	}

	// Francis-Yates implementation, https://stackoverflow.com/a/6274381
	shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
	};

	// naive method to remove duplicates
	removeDuplicateEntries(arr) {
		let ids = new Set();
		let res = [];
		arr.forEach(el => {
			if (!ids.has(el.id)) {
				res.push(el);
				ids.add(el.id);
			}
		})
		return res;
	}

	async getEntries(locale) {
		let entries = [];
		const { entriesCollection, groupStatsDoc } = this.props.firebase;
		const { country_code, region_code } = locale || DEFAULT_LOCALE;


		try {
			// get number of entries in group
			const groupStats = await groupStatsDoc.get();
			const groupSize = groupStats.data().size;

			if (groupSize < sizeThreshold) {
				// simply grab all entries and shuffle them
				const query = await entriesCollection.where('group', '==', group).get();
				entries = this.shuffle(query.docs).slice(-numEntries).map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
			} else {
				// try querying until there are 5 entries or we give up
				for (let _ = 0; _ < maxQueryTries; _++) {
					// if query is for US, filter based off state
					let query = (group === 'leveler' && country_code === 'US') ?
					entriesCollection.where('location.state', '==', region_code) :
					entriesCollection;

					// query based on group and random number
					const random = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
					query	= await query
						.where('group', '==', group)
						.where('random', '>=', random)
						.limit(numEntries)
						.get();

					// add new entries and remove any duplicates, if there are more than 5, keep the last 5
					entries = query.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id })).concat(entries);
					entries = this.removeDuplicateEntries(entries).slice(-numEntries);

					// if there are 5 entries, finish
					if (entries.length === numEntries) {
						break;
					}
				}
			}

			// update the shown count for each entry
			entries.forEach((doc) => this.updateShownCount(doc.id));

			this.setState({
				entries,
				loading: false,
			});
		} catch (e) {
			console.error(e.message);
		}
	}

	async updateShownCount(docId) {
		const { fieldValue, entriesCollection } = this.props.firebase;
		const docRef = entriesCollection.doc(docId)
		try {
			await docRef.update({
				shown: fieldValue.increment(1),
				random: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
			})
		} catch (e) {
			console.error(e.message)
		}
	}

	distributeClick = () => {
		if (!this.state.modalHasBeenShown) {
			this.setState({
				showModal: true,
				modalHasBeenShown: true
			})
		}
	}

	closeModal() {
		this.setState({
			showModal: false
		})
	}

	render() {

		const { entries } = this.state;
		const { fieldValue, entriesCollection, groupStatsDoc, errorCollection, logEvent } = this.props.firebase;
		return (
			<div>
				<DistributeHeader />
				{this.state.loading && <Loading height="100" width="100"/>}
				{entries.map(entry => (
					<DistributeCard
						entry={entry}
						key={entry.id}
						fieldValue={fieldValue}
						entriesCollection={entriesCollection}
						groupStatsDoc={groupStatsDoc}
						errorCollection={errorCollection}
						logEvent={logEvent}
						onCheckboxClick={this.distributeClick}
					/>
					))}
				<ShareModal closeModal={this.closeModal} modalIsOpen={this.state.showModal}/>
				<DonorForm />
			</div>
		)
	}
}

const DistributeTable = withFirebase(DistributeTableBase);

export default DistributePage;

export { DistributeTable };
