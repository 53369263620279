import * as React from "react";

function SvgLeveler(props) {
  return (
    <svg width={24} height={24} {...props}>
      <image
        width={24}
        height={24}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN RQfkBAEVCQm+zlDnAAAB6UlEQVRYw+2Xy0ocQRSGv+5qHBMvTGSSRUfHle4UpHAlgpNkHUggZF4i IcQsfQd9grgUF+rChRdIBpJl6G3AeCMXKUlIwDFDDEp3Fo41mumZvs2Ai/57c6g+9X/Vh0NXlYGW FIzymAcMkUUQXS5ldimxhOOcXgwa2j7PM4rcrY3E1neWmHM+XQHICWYZT2xd00deOWvAeSnkBPOM tNAebjNlb6ltECDzvPaz9yL4+dS1F2mX1A8hDWZ4ksy+ASLHTXvdZIynLS3OZT1k0uIRA81yflOp G8uSCQfoo2hxv3nOMapuLENn2BIWTIbbViCAQZNsWwHCCvopdNBd7ZAz/sQgWEEJfdwCwOCI/cjN GwJgVNdvYMaqUehZ0dceERBXKSAFpIAUkAJSwPUCGA3iIAVs+n850aYVvS9XMHTcGXCMDACU+abj 2rZ/qL/Bo587SQBeg9OE5xP5y8SlrbI4qh7dfCXoCDAIOHm6FnvIxu+z9CQDHFiUmgFErAvzJb0z WeYwmUcTlVk0+cBK2wAbvBHKtXco1DdztAu/b/YXXjifBaif9j5T9NZPCv/46BfTzka1CdSOvYUk 18LifOUli8rTXaa27bd0MciNFpgfs8pzZ1N5/xVPZpikyD3ysXvT5YD3LFBy9OX6H0fTabf3VgP2 AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA0LTAxVDIxOjA5OjA5KzAzOjAwDFwmXgAAACV0RVh0 ZGF0ZTptb2RpZnkAMjAyMC0wNC0wMVQyMTowOTowOSswMzowMH0BnuIAAAAASUVORK5CYII="
      />
    </svg>
  );
}

export default SvgLeveler;
