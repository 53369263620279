import React from 'react';
import { Trans, useTranslation } from 'react-i18next';


const PaymentLinkInUse = (props) => {
  const { t } = useTranslation();
  const email = t('generic.generalEmail');

  return (
    <>
      {props.isOpen ? (
        <>
          <div
            aria-modal="true"
            role="dialog"
            className="modal"
          >
            <p>
              <Trans
                i18nKey="modals.paymentLinkMessage"
                values={{ email }}
                components={[<a href={`mailto:${email}`} />]} // eslint-disable-line jsx-a11y/anchor-has-content
              />
            </p>
          </div>
          <div className="modal-overlay" onClick={() => props.closeModal()} />
        </>
      ) : null}
    </>
  );
}

export default PaymentLinkInUse;
