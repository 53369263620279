/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { withAuthentication } from '../Session';
import AuthModal from '../Modal/AuthModal';
import { useTranslation } from 'react-i18next';

const group = process.env.REACT_APP_LEVELER_GROUP;

const validationSchema = (t) => {
  return (
    Yup.object({
      title: Yup.string()
        .max(100, t('resources.validation.titleMaxLen'))
        .required(t('resources.validation.titleIsRequired')),
      category: Yup.string()
        .required(t('resources.validation.categoryIsRequired')),
      url: Yup.string(),
      text: Yup.string()
        .max(500, t('resources.validation.textMaxLen')),
    })
  );
}

const AddResourceForm = ({ authUser, userData, firebase }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const { t } = useTranslation();

  const toggleModal = () => {
    setIsOpen(!modalIsOpen);
  };

  const onLoginClick = () => {
    toggleModal();
  };

  const categories = [
    'EMERGENCY FUNDING',
    'FUNDING OPPORTUNITIES',
    'CROWDFUNDING',
    'GRANT',
    'FUN',
    'DISCUSS',
    'LGBTQ',
  ];

  const updateUser = async (resourceId) => {
    const { fieldValue, userCollection } = firebase;
    try {
      await userCollection.doc(authUser.uid)
        .update({
          karma: fieldValue.increment(3),
          submitted: fieldValue.arrayUnion(resourceId),
        });
    } catch (e) {
      console.log(e);
    }
  };

  const writeToResources = async (writeObj) => {
    const { resourcesCollection } = firebase;

    try {
      const resourceWrite = await resourcesCollection
        .add(writeObj);
      if (resourceWrite) {
        updateUser(resourceWrite.id);
        setSubmittedForm(true);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const prepResourceObject = (valuesObj) => {
    let {
      title, url, category, text,
    } = valuesObj;
    const { fieldValue } = firebase;
    const { id } = userData;

    // TRIM WHITESPACE
    title = title.trim();
    url = url.trim();
    if (text) {
      text = text.trim();
    } else {
      text = '';
    }

    const writeObj = {
      created: fieldValue.serverTimestamp(),
      by: id,
      title,
      url,
      category,
      type: 'story',
      score: 1,
      descendants: 0,
      kids: null,
      parent: null,
      text,
      group,
    };
    writeToResources(writeObj);
  };

  return (
    <>
      {authUser && !submittedForm && (
        <Formik
          initialValues={{ title: '', url: '', category: '' }}
          validationSchema={validationSchema(t)}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              prepResourceObject(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          <Form className="resource-form">
            <h4>{t('resources.newResourceHeader')}</h4>
            <label htmlFor="title">{t('resources.newResourceTitleLabel')}*</label>
            <Field name="title" type="text" />
            <ErrorMessage component="span" name="title" />
            <label htmlFor="category">{t('resources.newResourceCategoryLabel')}*</label>
            <Field name="category" as="select">
              <option value="">{t('resources.selectCategory')}</option>
              {categories.map((category) => (
                <option value={category}>{category}</option>
              ))}
            </Field>
            <ErrorMessage component="span" name="category" />
            <label htmlFor="url">
              url
              {' '}
              <span>({t('optional')})</span>
            </label>
            <Field name="url" type="text" />
            <ErrorMessage component="span" name="url" />
            <label htmlFor="text">
              {t('resources.newResourceDescriptionLabel')}
              {' '}
              <span>({t('optional')})</span>
            </label>
            <Field name="text" type="text" component="textarea" />
            <ErrorMessage component="span" name="text" />

            <button type="submit" className="btn">{t('resources.submitBtn')}</button>
          </Form>
        </Formik>
      )}

      {authUser && submittedForm && (
        <div className="submission-msg">
          <h3>{t('resources.submitCompleteHeader')} <span aria-hidden="true">✅</span></h3>
          <p>{t('resources.submitComplete')} <span aria-hidden="true">🔼</span></p>
          <Link to="/resources">
            <button type="button" className="btn">{t('resources.submitBackToResources')}</button>
          </Link>
        </div>
      )}

      {!authUser && (
        <>
          <AuthModal
            isOpen={modalIsOpen}
            toggleModal={toggleModal}
            firebase={firebase}
          />
          <div className="login-prompt">
            <p>{t('resources.loginPrompt')}</p>
              <button onClick={onLoginClick} className="btn">{t('resources.loginBtn')}</button>
          </div>
        </>
      )}

    </>
  );
};

export default withAuthentication(AddResourceForm);
