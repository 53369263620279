import React from 'react';
import { useTranslation } from 'react-i18next';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const DonorForm = () => {
  const { t } = useTranslation();

  const MAILCHIMP_RECEIVER_URL = 'https://leveler.us19.list-manage.com/subscribe/post?u=684bbb5a5125c30c1f0016222&id=7ce6b1f4ff';

  return (
    <div className="DonorForm">
      <p><strong>{t('donorForm.header')}</strong></p>
      <MailchimpSubscribe url={MAILCHIMP_RECEIVER_URL} />
    </div>
  );
};

export default DonorForm;
