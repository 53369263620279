import * as React from "react";

function SvgCash(props) {
  return (
    <svg viewBox="0 0 64 64" {...props}>
      <g fill="#FFF">
        <path
          d="M41.7 0c6.4 0 9.6 0 13.1 1.1a13.6 13.6 0 018.1 8.1C64 12.7 64 15.9 64 22.31v19.37c0 6.42 0 9.64-1.1 13.1a13.6 13.6 0 01-8.1 8.1C51.3 64 48.1 64 41.7 64H22.3c-6.42 0-9.64 0-13.1-1.1a13.6 13.6 0 01-8.1-8.1C0 51.3 0 48.1 0 41.69V22.3c0-6.42 0-9.64 1.1-13.1a13.6 13.6 0 018.1-8.1C12.7 0 15.9 0 22.3 0h19.4z"
          fill="#00D632"
        />
        <path d="M42.47 23.8c.5.5 1.33.5 1.8 0l2.5-2.6c.53-.5.5-1.4-.06-1.94a19.73 19.73 0 00-6.72-3.84l.79-3.8c.17-.83-.45-1.61-1.28-1.61h-4.84a1.32 1.32 0 00-1.28 1.06l-.7 3.38c-6.44.33-11.9 3.6-11.9 10.3 0 5.8 4.51 8.29 9.28 10 4.51 1.72 6.9 2.36 6.9 4.78 0 2.49-2.38 3.95-5.9 3.95-3.2 0-6.56-1.07-9.16-3.68a1.3 1.3 0 00-1.84 0l-2.7 2.7a1.36 1.36 0 000 1.92c2.1 2.07 4.76 3.57 7.792 4.4l-.74 3.57c-.17.83.44 1.6 1.27 1.61l4.85.04a1.32 1.32 0 001.3-1.06l.7-3.39C40.28 49.07 45 44.8 45 38.57c0-5.74-4.7-8.16-10.4-10.13-3.26-1.21-6.08-2.04-6.08-4.53 0-2.42 2.63-3.38 5.27-3.38 3.36 0 6.59 1.39 8.7 3.29z" />
      </g>
    </svg>
  );
}

export default SvgCash;
