import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as ROUTES from '../../constants/routes';

const Header = () => {
  const { t } = useTranslation();
  console.log(t('header.logoSubtext'));
  const emoji = t('header.emoji');
  return (
    <header>
      <Link to={ROUTES.HOME}>
        {emoji !== 'header.emoji' && emoji !== '' && <span>{t('header.emoji')}</span>}
        <img src="/leveler-logo.png" alt="Logo" />
      </Link>
      <p className="top">{t('header.logoSubtext')}</p>
    </header>
  );
};

export default Header;
