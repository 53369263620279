import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { withFirebase } from '../Firebase';

const FooterNav = ({ firebase, distributePage }) => {
  const { t } = useTranslation();
  const [bottomPadding, setBottomPadding] = useState('');

  useEffect(() => {
    if (distributePage) {
      setBottomPadding('90px');
    }
  }, [distributePage]);

  const onAboutClick = () => {
    const { logEvent } = firebase;
    logEvent('contribute_url_clicked_footer_nav');
  };

  const onContributeClick = () => {
    const { logEvent } = firebase;
    logEvent('contribute_url_clicked_footer_nav');
  };

  return (
    <footer style={{ paddingBottom: bottomPadding }}>
      <Link to="/about" onClick={onAboutClick}>
        <ReactMarkdown
          source={t('footerNav.about')}
          disallowedTypes={['paragraph']}
          unwrapDisallowed
        />
      </Link>

      <Link
        to="/contribute"
        onClick={onContributeClick}
      >
        <ReactMarkdown
          source={t('footerNav.contribute')}
          disallowedTypes={['paragraph']}
          unwrapDisallowed
        />
      </Link>
    </footer>
  );
};

FooterNav.propTypes = {
  firebase: PropTypes.object.isRequired,
  distributePage: PropTypes.bool.isRequired,
};

export default withFirebase(FooterNav);
