import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const buttonStyle = {
  cursor: 'pointer',
  color: 'darkslategray',
  backgroundColor: 'transparent',
  fontWeight: 'bold',
  border: 0,
};

const ShareButton = ({ url }) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);

  const copyOnClick = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      {!copied ? (
        <CopyToClipboard text={url} onCopy={() => copyOnClick()}>
          <button style={buttonStyle} type="button">{t('resources.shareBtn')}</button>
        </CopyToClipboard>
      )
        : <button style={buttonStyle} disabled type="button">{t('resources.shared')}</button>}
    </>
  );
};
export default ShareButton;
